body {
  padding-top: 60px; }

footer {
  margin-top: 40px;
  margin-bottom: 20px; }

@media (max-width: 1199px) {
  body {
    padding-top: 50px; } }

@media (max-width: 767px) {
  body {
    padding-top: 65px; } }

@media print {
  * {
    transition: none !important; }
  div.radio {
    margin-bottom: 3px !important; }
  hr {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  #wrapper {
    border: 0 !important;
    padding-left: 0 !important;
    width: 100% !important; }
  body {
    padding-top: 0 !important; }
  table {
    margin-bottom: 0 !important; }
  #sidebar-wrapper {
    width: 0 !important; }
  .form-control {
    font-size: 0.9em !important; }
  body {
    font-size: 0.9em !important; }
  .text-box {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px; }
  .img-thumbnail {
    height: 100px !important; }
  .break-after {
    page-break-after: always; } }

/* tabla cabecera rotada*/
.table-header-rotated th.row-header {
  width: auto; }

.table-header-rotated td {
  width: 40px;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  vertical-align: middle;
  text-align: center; }

.table-header-rotated th.rotate-45 {
  height: 80px;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  position: relative;
  vertical-align: bottom;
  padding: 0;
  font-size: 12px;
  line-height: 0.8; }

.table-header-rotated th.rotate-45 > div {
  position: relative;
  top: 0;
  left: 40px;
  /* 80 * tan(45) / 2 = 40 where 80 is the height on the cell and 45 is the transform angle*/
  height: 100%;
  transform: skew(-45deg, 0deg);
  overflow: hidden;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-top: 1px solid #dddddd; }

.table-header-rotated th.rotate-45 span {
  transform: skew(45deg, 0deg) rotate(315deg);
  position: absolute;
  bottom: 30px;
  /* 40 cos(45) = 28 with an additional 2px margin*/
  left: -25px;
  /*Because it looked good, but there is probably a mathematical link here as well*/
  display: inline-block;
  width: 85px;
  /* 80 / cos(45) - 40 cos (45) = 85 where 80 is the height of the cell, 40 the width of the cell and 45 the transform angle*/
  text-align: left; }

/* fin de cabecera rotada */
@media screen {
  .sheet {
    border: 1px solid #ccc;
    box-shadow: 10px 10px 5px #EEE;
    padding: 10px;
    margin-bottom: 10px; }
  a#menu-inner {
    margin: 0;
    padding: 20px 22px; }
  .sub-header {
    padding-bottom: 10px; } }

/*-- GENERAL --*/
.sub-header {
  border-bottom: 1px solid #eee; }

a {
  cursor: pointer; }

#wrapper {
  margin-left: 0;
  transition: all 0.5s ease; }

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  width: 0;
  height: 100%;
  padding: 0;
  background-color: #f5f5f5;
  border-right: 1px solid #eee;
  transition: all 0.5s ease; }

#page-content-wrapper {
  padding: 10px 0; }

#sidebar-wrapper.toggled {
  width: 0; }

#wrapper.toggled {
  margin-left: 0; }

@media (max-width: 767px) {
  #wrapper.toggled {
    margin-left: 0; }
  #sidebar-wrapper.toggled {
    width: 270px; } }

@media (min-width: 768px) {
  #wrapper {
    margin-left: 270px; }
  #sidebar-wrapper {
    width: 270px; }
  .sidebar {
    padding: 20px; } }

.placeholders {
  margin-bottom: 30px;
  text-align: center; }

.placeholders h4 {
  margin-bottom: 0; }

.placeholder {
  margin-bottom: 20px; }

.placeholder img {
  display: inline-block;
  border-radius: 50%; }

ul.seleccionaEmp li {
  padding-left: 15px; }

.navbar-right {
  padding-right: 10px; }

/* New Sidebar */
#sidebar-wrapper {
  background-color: #1e1e1e; }

/* Let's get this party started */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(214, 214, 214, 0.9);
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */ }

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(214, 214, 214, 0.7); }

/* Utiles */
.marginTop10 {
  margin-top: 10px; }

.marginTop20 {
  margin-top: 20px; }

.marginTop30 {
  margin-top: 30px; }

.marginTop40 {
  margin-top: 40px; }

.marginTop50 {
  margin-top: 50px; }

.marginTop60 {
  margin-top: 60px; }

.glyphicon-remove {
  cursor: pointer; }

.form-control.width80 {
  width: 80%;
  display: inline;
  margin-bottom: 10px; }

.prototypes {
  display: none; }

.change-message {
  display: none; }

.aprobado,
.borrador,
.editable {
  cursor: pointer; }

.control-thin {
  margin: 0 !important;
  padding: 0 !important;
  height: 22px !important; }

table table,
table table td,
table table tr {
  background: transparent !important;
  border: none !important; }

.registro-riesgo td {
  vertical-align: middle !important; }

.btn-primary {
  background-color: #8cc04d !important;
  color: white !important; }

tr.success th {
  background-color: #8cc04d !important;
  color: white !important; }

a.link {
  color: #cc0000 !important; }

.navbar-danger {
  background-color: #cc0000 !important;
  color: white !important; }
  .navbar-danger input {
    background-image: none !important; }
  .navbar-danger .dropdownjs::after {
    color: white;
    top: -3px; }

.dropdownjs li {
  color: #333; }

.table-condensed {
  margin-bottom: 0 !important; }

.titulo-safety h3 {
  margin-top: 15px !important;
  padding-top: 5px !important;
  padding-bottom: 5px;
  padding-left: 5px !important;
  font-weight: bold !important;
  font-size: 1.2em !important;
  background-image: url("/images/fondo-titulo-largo.gif");
  background-repeat: no-repeat;
  background-size: auto 35px;
  background-position: -100px; }

.titulo-safety.preliminar h3 {
  background-position: -50px !important; }

.titulo-safety {
  padding-right: 0 !important; }

h5 {
  font-weight: bold !important; }

.form-group {
  margin-top: 0 !important; }

.inventario-offset h3 {
  background-position: -200px !important; }

.editable {
  background: #f9f9f9 !important;
  padding: 5px; }

.flecha-der,
.flecha-iz {
  padding: 40px;
  background-repeat: no-repeat;
  background-size: 100% 100%; }

.flecha-iz {
  background-image: url("/images/flechas-bowtie-01.png"); }

.flecha-der {
  background-image: url("/images/flechas-bowtie-02.png"); }

.bowtie-center th {
  background: #ccc;
  color: white; }

.bowtie-center table *,
.bowtie-center table th {
  border: 1px solid white !important; }

.bowtie-center th.nivel-control {
  background: #777; }

.bowtie-center td {
  background: #eee; }

.bowtie-center .riesgo-fatalidad th {
  background: #fcc00e;
  color: black; }

.bowtie-center .riesgo-fatalidad td {
  background: rgba(255, 221, 0, 0.3); }

.subtitulos h5 {
  border-left: 50px solid #c02a32;
  background: #bbb;
  padding: 7px;
  margin-left: -19px; }

.subtitulos h6 {
  background: #bbb;
  padding: 7px;
  font-size: 1em;
  font-weight: bold; }

.subtitulos.inner h6 {
  border-left: 10px solid #c02a32; }

#tablaAccionControl td {
  border: none; }

table .form-group {
  padding-bottom: 0 !important; }

table.table-condensed {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

table.table-condensed .form-control {
  margin-bottom: 0 !important; }

table.unbordered,
table.unbordered tbody,
table.unbordered td,
table.unbordered th,
table.unbordered thead,
table.unbordered tr {
  border: none !important; }

.empresa-actual,
.nombre-usuario {
  font-weight: 600; }

.info-factor-psicosocial img.pull-left {
  margin-right: 15px; }

.info-factor-psicosocial img.pull-right {
  margin-left: 15px; }

.encuesta th {
  width: 60px; }

.trabajadores {
  width: 100px; }

.checkbox {
  margin-top: 0 !important; }

.tabs-dashboard {
  margin-bottom: 15px;
  background: red; }

.tabs-dashboard li.active {
  border-bottom: 4px solid #FFAAAA; }

.dashboard_brief .nav > li > a > img {
  max-width: 100% !important; }

.dashboard_brief li {
  background: rgba(255, 0, 0, 0.5); }

.datos_personales p {
  margin-bottom: 3px; }

.dashboard_brief .nav > li > a:focus,
.dashboard_brief .nav > li > a:hover,
.dashboard_brief .nav > li.active > a {
  background: red; }

.dashboard_brief .nav > li > a {
  padding-top: 0;
  padding-bottom: 0; }

.dashboard_brief h5,
.odi_brief h5 {
  padding: 5px; }

.dashboard-empresa .tabs-dashboard {
  background-color: #009688; }

.dashboard-empresa .dashboard_brief li a:hover,
.dashboard-empresa .dashboard_brief li.active a {
  background-color: #009688; }

.dashboard-empresa .dashboard_brief li {
  background: transparent; }

.dashboard-empresa .dashboard_brief li a {
  background-color: rgba(0, 150, 136, 0.6); }

iframe {
  border: none !important; }

.change-unidad-hijos {
  background: transparent; }

.titulo-verificaciones {
  font-weight: bold;
  margin: 0 !important; }

.td-label {
  background: #ccc;
  vertical-align: middle; }

.table-bowtie {
  font-size: 11px; }

.table-bowtie td {
  border: none !important; }

.table-controles {
  font-size: 11px; }

.cursos-cuadricula {
  border: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 10px; }

.responsive-calendar .day.today a {
  background: #1d86c8;
  color: #ffffff; }

.responsive-calendar .day.active a {
  background: #ffffff;
  color: red;
  font-weight: 500; }

.responsive-calendar .day.active a:hover {
  background-color: #eee; }

.badge {
  padding: 0; }

.margin60 {
  margin: 60px 0; }

.table-unbordered td,
.table-unbordered th {
  border-top: none !important; }

.actividades {
  font-size: 14px !important; }

.estadisticas h6 {
  font-size: 13px !important; }

/* credencial */
.credential {
  margin: auto;
  width: 362px;
  height: 227px;
  border: solid 1px;
  border-radius: 15px; }

#left-column {
  position: relative;
  float: left;
  width: 220px;
  height: 225px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #ffffff; }

#images-row {
  position: relative;
  float: left;
  top: 10px;
  width: 220px;
  height: 120px;
  padding: 5px; }

#photo {
  position: relative;
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 50%; }

#separator {
  position: relative;
  float: left;
  top: 15px;
  left: 6px;
  height: 70px;
  border-left: solid 1px #a8a7a7; }

#logo-empresa {
  position: relative;
  float: left;
  top: 15px;
  left: 6px;
  width: 100px;
  max-height: 60px; }

#info-row {
  position: relative;
  float: left;
  width: 220px;
  height: 105px;
  border-bottom-left-radius: 15px;
  padding: 10px 12px; }

#info-table {
  color: #000000;
  font-size: 12px; }

#info-table td {
  max-width: 100px;
  white-space: nowrap; }

#info-table td.info-text {
  max-width: 144px;
  overflow: hidden;
  padding-left: 5px; }

#info-table td.sm-text {
  font-size: 11px; }

#right-column {
  position: relative;
  float: left;
  width: 140px;
  height: 225px;
  padding: 5px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #e63a36; }

#text-1 {
  position: relative;
  float: left;
  top: 20px;
  width: 130px;
  height: 20px;
  font-size: 12px;
  color: #ffffff; }

#qr-code {
  position: relative;
  float: left;
  top: 25px;
  left: 25px;
  width: 80px;
  height: 80px; }

#logos-row {
  position: relative;
  float: left;
  top: 30px;
  width: 140px;
  height: 80px; }

#safetyclub-logo {
  position: relative;
  float: left;
  width: 65px;
  max-height: 60px; }

#separator2 {
  position: relative;
  float: left;
  top: 10px;
  left: 3px;
  height: 25px;
  border-left: solid 1px #ffffff; }

#lifeon-logo {
  position: relative;
  float: left;
  top: 3px;
  left: 3px;
  width: 65px;
  max-height: 60px; }

#text-2 {
  position: relative;
  float: left;
  top: -5px;
  width: 130px;
  height: 20px;
  font-size: 12px;
  color: #ffffff; }

#text-3 {
  position: relative;
  float: left;
  top: -5px;
  width: 130px;
  height: 20px;
  font-size: 12px;
  color: #ffffff; }

#back-logo {
  position: relative;
  float: left;
  top: 50px;
  left: 75px;
  width: 200px; }

#text-4 {
  position: relative;
  float: left;
  top: 70px;
  width: 360px;
  height: 20px;
  font-size: 15px;
  color: #000000; }

.imagen_cursos {
  display: inline-block;
  margin: 4px;
  border: 1px solid #CCCCCC;
  background-position: center center;
  background-repeat: no-repeat; }

.imagen_cursos.size-fluid {
  padding-top: 70%;
  width: 100%; }

.imagen_cursos.scale-fill {
  background-size: cover; }

.imagen_cursos img {
  display: none; }

.vista_sso td,
.vista_sso th {
  font-size: 13px; }

.text-disabled {
  color: #999 !important; }

.regular-radio {
  display: none; }

.regular-radio + label {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 50px;
  display: inline-block;
  position: relative; }

.regular-radio:checked + label:after {
  content: ' ';
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  background: #cc0000;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  text-shadow: 0;
  left: 3px;
  font-size: 32px; }

.regular-radio:checked + label {
  background-color: #e9ecee;
  color: #99a1a7;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1); }

.regular-radio + label:active,
.regular-radio:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 1px 3px rgba(0, 0, 0, 0.1); }

.big-radio + label {
  padding: 16px;
  cursor: pointer; }

.big-radio:checked + label:after {
  width: 24px;
  height: 24px;
  left: 4px;
  top: 4px; }

.tabs-investigacion li.active a {
  border-bottom: 5px solid white !important;
  border-top: none !important; }

.table-2 td {
  padding: 5px; }

.tabla-votacion td {
  vertical-align: middle !important; }

.no-margin {
  margin: 0; }

.nav > li, .nav > li > a {
  float: left; }

.navbar-right {
  float: right; }

.navbar-left {
  float: left; }

.label-pendiente {
  background-color: #FDD835; }

.autocomplete-suggestions {
  background: white;
  font-size: 15px;
  font-weight: normal;
  overflow: auto; }

.autocomplete-selected {
  background: #F0F0F0; }

/* Let's get this party started */
#sidebar-wrapper::-webkit-scrollbar {
  width: 10px; }

/* Track */
#sidebar-wrapper::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px; }

/* Handle */
#sidebar-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(214, 214, 214, 0.3);
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */ }

#sidebar-wrapper::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(214, 214, 214, 0.1); }

.sidebar-menu {
  white-space: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  padding: 0;
  height: calc(100vh - 260px); }

.logo-empresa-contrato {
  width: 100%;
  position: absolute;
  bottom: 80px; }
  .logo-empresa-contrato img {
    max-height: 70px; }

.sidebar-menu .header {
  color: #4b646f;
  background: #1a2226;
  padding: 10px 25px 10px 20px;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip; }

.sidebar-menu .treeview a {
  color: #b8c7ce;
  padding: 7px 5px 7px 15px;
  text-decoration: none;
  display: block;
  border-left: 3px solid transparent;
  font-size: 13px;
  font-weight: 400; }

.sidebar-menu .treeview a:hover,
.sidebar-menu .treeview li.active a,
.sidebar-menu .treeview.active2 a {
  color: #fff;
  background: #1e282c;
  border-left-color: #ffb600;
  transition: all 0.1s ease; }

.sidebar-menu .treeview-menu .treeview-menu {
  padding-left: 20px; }

.sidebar-menu .treeview a .glyphicon-menu-left,
.sidebar-menu .treeview-menu a .glyphicon-menu-left {
  margin-right: 5px;
  margin-top: 4px;
  font-size: 10px; }

.sidebar-menu .treeview .icon {
  width: 24px;
  float: left; }

.sidebar-menu .treeview-menu {
  margin: 0 1px;
  background: #2c3b41;
  list-style: none;
  display: none;
  padding: 0 0 0 5px; }

.sidebar-menu .treeview-menu a {
  padding: 5px 5px 5px 15px;
  display: block;
  color: #8aa4af;
  text-decoration: none;
  font-size: 13px; }

.sidebar-menu .treeview-menu a:hover,
.sidebar-menu .treeview-menu li.active a {
  color: #fff;
  border-left-color: transparent;
  background: transparent;
  transition: all 0.1s ease; }

.sidebar-menu .treeview-menu .fa {
  width: 20px;
  font-size: 10px; }

.sidebar-select-empresa {
  color: #fff;
  padding: 10px 10px 5px 15px; }
  .sidebar-select-empresa input {
    color: #fff;
    background-image: none !important; }
  .sidebar-select-empresa .dropdownjs::after {
    color: #fff; }

.user-panel {
  padding: 20px 10px 10px 15px;
  overflow: hidden; }

.user-panel .image {
  width: 20%; }
  .user-panel .image img {
    width: 100%;
    max-width: 45px;
    height: auto; }

.user-panel .info {
  padding: 5px 5px 5px 15px;
  line-height: 1;
  width: 80%; }
  .user-panel .info p {
    font-weight: 600;
    margin-bottom: 9px;
    color: #fff; }
  .user-panel .info p.second-line {
    font-weight: 300;
    font-size: 12px;
    width: 100%;
    margin-bottom: 9px;
    color: #fff; }

@media (max-width: 767px) {
  .user-panel {
    padding: 5px 10px 0 15px; } }

.verificacionEjecutivo_Build #wrapper-verificacion-ejecutivos {
  position: relative;
  width: 960px;
  margin: 20px; }

.verificacionEjecutivo_Build .cabecera div img {
  width: 150px; }

.verificacionEjecutivo_Build .evento-top {
  position: relative;
  float: left;
  background-image: url(/images/franjaSeguridad.jpg);
  height: 0px;
  width: 750px;
  float: left;
  left: 10px;
  padding: 30px;
  box-shadow: 0 0 7px grey; }

.bhp-billiton .navbar-danger, .minera-escondida .navbar-danger {
  background-color: #E85507 !important;
  color: white !important; }

.minera-escondida #sidebar-wrapper {
  background-color: #092b43; }

.cmpc .navbar-danger {
  background-color: #018D36 !important;
  color: white !important; }

.cmpc .tabs-dashboard {
  background-color: #40A43F !important; }
  .cmpc .tabs-dashboard li.active {
    border-bottom: 4px solid #87878D !important; }

.cmpc .logo-empresa-contrato img {
  max-height: 100px !important; }

/*# sourceMappingURL=app.css.map */
