/*
.tabla-rr{
border: solid 1px #dff0d8;
}
.tabla-rr tr, .tabla-rr th, .tabla-rr td{
border: solid 1px #dff0d8;
padding: 5px;
}

.tabla-rr td.big-td{
min-width: 200px;
}
*/

#tabla-registro-riesgos{
	min-width: 1200px
}

.text-sm{
font-size: 11px;
color: #5b5b5b;
}

td.big-td{
min-width: 200px;
}

/*.peligro-box{
height: 30px;
overflow-y: scroll;
border-bottom: solid 1px #f5f5f5;
}

.end-box{
height: 30px;
overflow-y: scroll;
border-bottom: solid 1px #f5f5f5;
}*/

.control-box{
height: 30px;
overflow-y: scroll;
border-bottom: solid 1px #f5f5f5;
}


.nr-selected {
    font-weight: 900;
    background: rgba(255, 47, 0, 0.48) !important;
}


  td.big-td{
    min-width: 200px;
  }

  table.table-nr {
    text-align: center;
    margin: 0 5% 0 0;
  }
  table.table-nr tr td{
    height: 30px;
    width: 80px;
    border-bottom: solid 1px #dddddd;
  }
  table.table-nr tr td.info{
    background: #D9EDF7;
  }
  table.table-nr tr td.success{
    background: #DFF0D8;
  }
  table.table-nr tr td.warning{
    background: #FCF8E3;
  }
  table.table-nr tr td.danger{
    background: #F2DEDE;
  }