@font-face {
  font-family: 'icomoon2';
  src:  url('/build/fonts/lifeon/icomoon2.eot?pc7e4c');
  src:  url('/build/fonts/lifeon/icomoon2.eot?pc7e4c#iefix') format('embedded-opentype'),
    url('/build/fonts/lifeon/icomoon2.ttf?pc7e4c') format('truetype'),
    url('/build/fonts/lifeon/icomoon2.woff?pc7e4c') format('woff'),
    url('/build/fonts/lifeon/icomoon2.svg?pc7e4c#icomoon2') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-salud-ocupacional:before {
  content: "\e90e";
}
.icon-investigacion-accidentes:before {
  content: "\e911";
}
.icon-comite-paritario:before {
  content: "\e913";
}
.icon-gestion-persona-empresa:before {
  content: "\e900";
}
.icon-inventario-riesgos:before {
  content: "\e901";
}
.icon-estadisticas-seguridad:before {
  content: "\e902";
}
.icon-planes-de-accion:before {
  content: "\e903";
}
.icon-factores-psicosociales:before {
  content: "\e904";
}
.icon-herramientas-preventivas:before {
  content: "\e905";
}
.icon-epp:before {
  content: "\e906";
}
.icon-gestiones-incidentes:before {
  content: "\e907";
}
.icon-lecciones-aprendidas:before {
  content: "\e908";
}
.icon-generacion-competencias:before {
  content: "\e909";
}
.icon-gestion-documental:before {
  content: "\e90a";
}
.icon-diagnostico:before {
  content: "\e90b";
}
.icon-configuracion:before {
  content: "\e90c";
}
.icon-planificacion:before {
  content: "\e90d";
}
